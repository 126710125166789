import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import BMI from "../components/bmi"
import Banner from "../components/Banner"
import HeaderBanner from "../components/HeaderBanner"

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <article className="home-page">
         <a href="https://casino-professor.com/fi/" target="_blank" rel="noopener noreferrer"><HeaderBanner/></a>
        <h1>Painoindeksilaskuri / BMI-laskuri</h1>
        <p>Painoindeksilaskuri laskee sinulle painoindeksin (Body Mass Index, BMI) pituutesi ja painosi perusteella. Syötä pituutesi ja painosi alla oleviin kenttiin ja katso mikä on sinun painoindeksi.</p>
        <BMI />
        <h2>Painoindeksilaskurin tulokset</h2>
        <p>Normaalipainoinen ihminen on kuin painoindeksi on välillä 19 ja 24,9. Tämän välin alapuolelle sijoittuvat henkilöt ovat alipainoisia ja vastaavasti saman välin yli sijoittuvat henkilöt ovat ylipainoisia.</p>
        <Banner/>
        <h3>Alipaino - Painoindeksi alle 19</h3>
        <ul>
          <li>18 – 18,9: Lievä alipaino</li>
          <li>15 – 17,9: Suuri alipaino</li>
          <li>0 – 14,9: Sairas alipaino</li>
        </ul>
         <h3>Ylipaino - Painoindeksi yli 24,9</h3>
        <ul>
          <li>25,0 – 29,9: Lievä ylipaino</li>
          <li>30,0 – 34,9: Suuri ylipaino</li>
          <li>35 ja yli: Sairas ylipaino</li>
        </ul>       
        <h2>Painoindeksilaskurin tarkkuus</h2>
        <p>Painoindeksilaskuri on suuntaa antava mittari oman painon arviointiin eikä sitä pidä ottaa absoluuttisena totuutena. Painoindeksilaskuri ei nimittäin huomioi henkilön ruumiinrakennetta.</p>
        <p>Suurimmalle osalle väestöstä painooindeksilaskuri antaa melko hyvän kuvan omasta tilanteesta. Harva normaali ihminen on kovinkaan lihaksikas (ole rehellinen). Ihmisillä on yleensä tapana yliarvioida omat kykynsä. Lihasmassan kasvattaminen on vaikeaa ja yhdessä vuodessa voi oikeasti saada kilon tai kaksi lihasta ilman doping aineita.</p>
        <h2>Painoindeksilaskuri historia</h2>
        <p>Painoindeksilaskurin keksi belgialainen matemaatikko / tilastotieteilijä nimeltä <a href="https://fi.wikipedia.org/wiki/Adolphe_Qu%C3%A9telet">Lambert Adolphe Jacques Quetelet</a> 1830-luvulla. Painoindeksilaskuri sai positiivisen vastaanoton tuolloin vaikka moni onkin kyseenalaistanut sen pätevyyttä painoindeksin laskemiseen tänä päivänä.  Kyseenalaistaminen johtuu lähinnä siitä, että painoindeksi on erittäin huono mittaamaan kehon koostumusta. Useimmiten urheilijoilla painooindeksilaskuri saattaakin näyttää ylipainoa. Tämä johtuu siitä, että lihas painaa enemmän kuin läski.</p>
        <h2>Uusi painooindeksilaskuri</h2>
        <p>Uusi painooindeksilaskuri antaa hieman paremman kuuloisen tuloksen verrattuna vanhaan painoindeksilaskuriin. Uuden painoindeksilaskurin kehitti Nick Hale. Uusi painoindeksilaskurin antaa 5-7% paremman tuloksen (laihemman mitä vanha painooindeksilaskuri).</p>
        <h2>Mitä painooindeksilaskuri ei kerro?</h2>
        <p>Painoindeksilaskuri laskee painoindeksin pituuden ja painon perusteella. Tänä päivänä rasvaprosenttia pidetään erittäin tärkeänä mittarina terveellisen oman painon laskentaan. Tätä painooindeksilaskuri ei tietenkään kerro.</p>
        <h3>Terveellinen rasvaprosentti</h3>
        <p>Naisilla terveellinen rasvaprosentti on korkeampi kuin miehiellä johtuen anatomisista eroavaisuuksista sukupuolien välillä. Miehillä rasvaprosenttia alhaisempi kuina 24%, on ok, mutta ideaalisti se olisi alle 17%. Naisilla vastaavasti alle 31% on ok, joskin naisillakin ideaali rasvaprosentti olisi pienempi kuin suositus. Alle 24% olisi ideaali.</p>
        <h2>Laihduta painoindeksilaskurin avulla</h2>
        <p>Omaa painoa voi tarkkailla tietenkin vaa'an, mutta myös painoindeksilaskurin avulla. Aseta itsellesi tavoitepaino tai painoindeksilaskurin avulla ja tarkkaile joka päivä miten projetkti edistyy vaa'an avulla. Tällä tapaa myös opit tuntemaan mikä kalorimäärä on sinulle sopiva määrä painon pitämiseen. Tämän kalorimäärän alapuolelle meneminen on aina hieman tuskaista. Keho on nälkäinen mutta sinun ei tule syödä jos haluat painoa pudottaa. <a href="/laihduttaminen">Laihduttaminen</a> on aina helpompaa kuin toiminta on tavoitteellista.</p>
        <h3>Nopea painonpudotus</h3>
        <p>Painon pudottaminen nopeasti on usein mitä ihmiset haluavat mutta painon pudottaminen nopeasti usein johtaa epäonnistumiseen. Nopeiden tulosten näkeminen tosin voi joskus olla erittäin motivoivaa mutta liian tiukka dieetti harvoin johtaa pysyviin tuloksiin.</p>
        <p>Kaikkea nykyään halutaan nopeasti. Oli kyse painonpudotuksesta tai jostain muusta. Kuin uhkapelataan nettikasinolla ja voitetaan niin voitot halutaan kotiuttaa heti. <a href="https://www.nettikasinot.media/nopeat-kotiutukset/" target="_blank" rel="noopener noreferrer">Nopeat nettikasinot</a> maksavat kotiutukset nopeasti.</p>
      </article>
      <h2>Lisää aiheesta</h2>
      <div className="grids">
        {Posts}
      </div>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`