import React from 'react';
import image from './painoindeksilaskuri.png'; // Tell Webpack this JS file uses this image
console.log(image); 

function Banner() {
  // Import result is the URL of your image
  return <img className="home-img" src={image} alt="Painoindeksilaskuri tulokset" />;
}
export default Banner;

