import React, { Component } from 'react'

import Range from './Range';
import Output from './Output';

class BMI extends Component {
  constructor(props){
    super(props); 
    this.state = {
      height: 175,
      weight: 73, 
      bmi: 22.49, 
      bmiClass: 'Normaali'
    }
  }

  heightChange = (height) => {
    this.setState({ height: height}, this.setBmi ); 
  }

  weightChange = (weight) => {
    this.setState({ weight: weight }, this.setBmi ); 
  }

  setBmi = () => {
    let bmi = ((this.state.weight / this.state.height / this.state.height) * 10000).toFixed(2); 
    this.setState({ bmi: bmi, bmiClass: this.getBmiClass(bmi) }); 
  }

  getBmiClass = (bmi) => {
    if(bmi < 18.5) return 'Alipaino'; 
    if(bmi >= 18.5 && bmi <= 24.9) return 'Normaali'; 
    if(bmi >= 25 && bmi <= 29.9) return 'Ylipaino'; 
    if(bmi >= 30) return 'Liikalihavuus';  
  }

  render() {
    return (
      <div className="bmi-calculator">
        <form>
          <div>
            <p>Pituus</p>
            <Range 
              value={this.state.height} 
              onChange={this.heightChange} />
          </div>
          <div>
            <p>Paino</p>
            <Range 
              value={this.state.weight}
              onChange={this.weightChange} />
           </div>
         </form>
         <Output data={this.state}/>
        </div>
    );
  }
}

export default BMI
